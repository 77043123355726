import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { initalizerFactory } from "./initialization/initializer-factory";
import { InitializerProvider } from "./initialization/initializer-provider";
import { throwIfAlreadyLoaded } from "./module-import-guard";
import { BfcGoogleAnalyticsModule } from "@bfl/components/google-analytics";
import { ValidationErrorMessageService } from "./validation-error-message/validation-error-message.service";
import { AuthGuardZev } from "./auth/auth-guard-zev.service";
import { AccountService } from "./service/account.service";
import { UrlHelperService } from "./service/url.helper.service";
import { StringIdGenerator } from "./service/string-id-generator.service";
import { FileReaderService } from "./service/file-reader.service";
import { PdfHelperService } from "./pdf-helper/pdf-helper.service";
import { ApplicationService } from "./service/application.service";
import { BflOpSelfServiceModule } from "@bfl/op-self-service";
import { Base64HelperService } from "./service/base64-helper.service";
import { DatePipe } from "@angular/common";
import { DocHubDocumentService } from "./service/doc-hub-document.service";
import { DocumentUpdateService } from "./service/document-update-service";
import { BflOpMaintenancesModule } from "@bfl/op-maintenances";

@NgModule({
  imports: [HttpClientModule, BfcGoogleAnalyticsModule, BflOpMaintenancesModule, BflOpSelfServiceModule],
  exports: [],
  declarations: [],
  providers: [
    InitializerProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initalizerFactory,
      deps: [InitializerProvider],
      multi: true,
    },
    AuthGuardZev,
    ValidationErrorMessageService,
    AccountService,
    UrlHelperService,
    StringIdGenerator,
    FileReaderService,
    PdfHelperService,
    ApplicationService,
    Base64HelperService,
    DatePipe,
    DocHubDocumentService,
    DocumentUpdateService,
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, "CoreModule");
  }
}
