import { IZevConfiguration } from "../configuration";

export const LOCAL_ENV: IZevConfiguration = {
  apiUrl: "https://api-zev-t.bkw.ch/zev",
  // apiUrl: "http://localhost:8080/zev",
  installerApiUrl: "https://api-esti-t.bkw.ch/installers",
  zevGrantUrl: "http://localhost:8080/account/grant",
  siteUrl: "http://localhost:9001",
  opNewsApiUrl: "https://api-news-t.bkw.ch/news",
};
